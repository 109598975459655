import { Box, IconButton, Typography } from '@mui/joy';
import React from 'react';
//import css from './Header.module.css';
import { GiHamburgerMenu } from 'react-icons/gi';
import useWidth from '../../hooks/useWidth';
import { toggleSidebar } from '../../utils/closeSidebar';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import ToggleLanguage from '../ToggleLanguage/ToggleLanguage';
import { useTranslation } from 'react-i18next';
import Logo from '../Logo/Logo';
const Header = ({ showLogo }: { showLogo: boolean }) => {
  const { isMobile } = useWidth();
  const { t } = useTranslation();
  const { isLargeDesktop } = useWidth();
  const { user, isLoggedIn } = useSelector(getGlobalUser);

  return (
    <Box
      component="header"
      sx={{
        width: '100%',
        borderBottom: '1px solid var(--joy-palette-divider)',
        padding: isMobile ? '10px 0 0 0' : '10px 20px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        {!isLargeDesktop && (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <IconButton onClick={() => toggleSidebar()} variant="outlined" color="neutral" size="sm">
              <GiHamburgerMenu />
            </IconButton>
            <ToggleLanguage />
          </Box>
        )}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex' }}>
          {showLogo && isLargeDesktop && (<Logo />)}
        </Box>

        {isLoggedIn && !isMobile && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: showLogo ? "60px" : 1 }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" fill="none">
                <path
                  fill="#CED4DA"
                  d="M7.6 7.3a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2Zm0 2.4a7.2 7.2 0 0 1 7.2 7.2H.4a7.2 7.2 0 0 1 7.2-7.2Zm9.6-4.8a1.2 1.2 0 1 0-2.4 0v1.2h-1.2a1.2 1.2 0 1 0 0 2.4h1.2v1.2a1.2 1.2 0 1 0 2.4 0V8.5h1.2a1.2 1.2 0 1 0 0-2.4h-1.2V4.9Z"
                />
              </svg>
              <Typography
                level="body-md"
                sx={{ ml: 1, color: '#F1F1F1', fontWeight: 500 }}
              >
                {'Merchant'}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {user?.acquiring_tax !== undefined && (
                <>
                  <Typography
                    level="body-sm"
                    sx={{ color: '#868E96' }}
                  >
                    {t('feeHeader')} QAC:
                  </Typography>
                  <Typography
                    level="body-sm"
                    sx={{ color: '#F1F1F1', ml: '4px' }}
                  >
                    {user.acquiring_tax}%
                  </Typography>
                </>)}
              <Typography
                level="body-sm"
                sx={{ color: '#868E96', marginLeft: '40px', }}
              >
                {t('feeHeader')} P2P:
              </Typography>
              <Typography
                level="body-sm"
                sx={{ color: '#F1F1F1', ml: '4px', marginRight: '20px' }}
              >
                {user.tax}%
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
                  <path
                    fill="#ADB5BD"
                    d="M10 .5a5 5 0 1 1 0 10 5 5 0 0 1 0-10ZM10 13c5.5 0 10 2.2 10 5v2.5H0V18c0-2.8 4.5-5 10-5Z"
                  />
                </svg>
                <Typography
                  level="title-sm"
                  sx={{ ml: 1, color: '#F1F1F1' }}
                >
                  {user.name}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {isLargeDesktop && <ToggleLanguage />}
      </Box>
      {isLoggedIn && isMobile && (
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: 2,
            mt: 2
          }}
        >
          <Box sx={{ display: 'block' }}>
            <Typography level="body-sm" sx={{ color: '#F1F1F1', fontSize: '14px' }}>
              {t('feeHeader')} P2P: {user.tax}%
            </Typography>
            <Typography level="body-sm" sx={{ color: '#F1F1F1', fontSize: '14px' }}>
              {t('feeHeader')} QAC: {user.acquiring_tax}%
            </Typography></Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
              <path
                fill="#ADB5BD"
                d="M10 .5a5 5 0 1 1 0 10 5 5 0 0 1 0-10ZM10 13c5.5 0 10 2.2 10 5v2.5H0V18c0-2.8 4.5-5 10-5Z"
              />
            </svg>
            <Typography level="title-sm" sx={{ fontSize: '14px', ml: 1, color: '#F1F1F1' }}>
              {user.name}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default Header;
