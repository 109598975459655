import { Box, Divider, Stack, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWidth from '../../../hooks/useWidth';
import { ICourse } from '../../../utils/types';
import { getCourses } from '../../../services/operators';
import { socket } from '../../../socket';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../../redux/auth/selectors';

const Courses = () => {
  const { isMobile, isLargeDesktop } = useWidth();
  const { t } = useTranslation();
  const {  isLoggedIn, access_token } = useSelector(getGlobalUser);
  const [ courses, setCourses] = useState<ICourse[] | null>(null);

  useEffect(() => {
    if (isLoggedIn) {
      const fetch = async () => {
        const courses = await getCourses(access_token);
        setCourses(courses.all_rates);
      };
      fetch();

      console.log(courses);
    }
  }, []);

  useEffect(() => {
    socket.on('course', value => {
      console.log('here');
      console.log(value.all_rates);
      setCourses(value.all_rates);
    });
  }, []);


  return (
    <Box
      sx={{
        backgroundColor: '#111317',
        borderRadius: '16px',
        height: '100%',
        width: '100%',
        mt: !isLargeDesktop ? 3 : 0,
        ml: !isLargeDesktop ? 0 : 0,
        mb: !isLargeDesktop ? 3 : 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px 24px',
          backgroundColor: '#18191A',
          borderRadius: '16px 16px 0 0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
            <path
              stroke="url(#a)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="5.8"
              strokeWidth="2"
              d="M8 8v21.3a2.7 2.7 0 0 0 2.7 2.7H32"
            />
            <path
              stroke="url(#b)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="5.8"
              strokeWidth="2"
              d="m13.3 22.7 5.4-5.4 5.3 5.4 8-8"
            />
            <path
              stroke="url(#c)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="5.8"
              strokeWidth="2"
              d="M28 14.7h4v4"
            />
            <defs>
              <linearGradient
                id="a"
                x1="8.3"
                x2="32"
                y1="21.8"
                y2="21.8"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#684FDD" />
                <stop offset="1" stopColor="#2F92AC" />
              </linearGradient>
              <linearGradient
                id="b"
                x1="13.6"
                x2="32"
                y1="19.2"
                y2="19.3"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#684FDD" />
                <stop offset="1" stopColor="#2F92AC" />
              </linearGradient>
              <linearGradient
                id="c"
                x1="28.1"
                x2="32"
                y1="17"
                y2="17"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#684FDD" />
                <stop offset="1" stopColor="#2F92AC" />
              </linearGradient>
            </defs>
          </svg>
          <Typography
            level="h4"
            sx={{ Color: '#F1F1F1', fontSize: isMobile ? '16px' : '20px', fontWeight: 600 }}
          >
            {t('dashboard_currency_rates')}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: !isLargeDesktop ? 'block' : 'flex',
          p: 3,
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'block' }}>
          {courses && courses.length > 0 ? (
            courses.map((course, index) => (
              <React.Fragment key={index}>
                <Stack spacing={2}>
                  <Typography
                    level="title-sm"
                    sx={{
                      color: '#CED4DA',
                      fontSize: isMobile ? '14px' : '16px',
                      fontWeight: 400,
                    }}
                  >
                    <b
                      style={{
                        color: 'var(--joy-palette-text-primary, var(--joy-palette-neutral-800, #171A1C))',
                      }}
                    >
                      {course.fiat}:{' '}
                    </b>
                    {`${parseFloat(course.buy_cost).toFixed(2)} / ${parseFloat(
                      course.sell_cost
                    ).toFixed(2)}`}
                  </Typography>
                </Stack>
                <Divider />
              </React.Fragment>
            ))
          ) : (
            t('header_course_notfound')
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Courses;
