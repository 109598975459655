import { Box, Button, Option, Select, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Transactions from '../../components/Dashboard/Widgets/Transactions';
import Turnovers from '../../components/Dashboard/Widgets/Turnovers';

import useWidth from '../../hooks/useWidth';
import { formatDateBE, formatDateText } from '../../utils/datePickerFormat';
import DatePickerModal from '../../components/DatePicker';

import { getAllFiat, getAllStatistics, getOtherStatistics } from '../../services/operators';
import { Stats, OtherStats, itemType } from '../../utils/types';

import { getLastWeeksDate } from '../../utils/dateFormater';
import Statistics from '../../components/Dashboard/Widgets/Statistics';
import Courses from '../../components/Dashboard/Widgets/Courses';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
const defaultDate = new Date();

type filterData = {
  sDate: string;
  fDate: string;
  currency: string;
};
const initialState = {
  sDate: formatDateBE(getLastWeeksDate().toString()),
  fDate: formatDateBE(defaultDate.toString()),
  currency: 'UAH'
};
type ItemRange = {
  selection: itemType;
}
const Dashboard = () => {
  const [filterQueries, setFilterQueries] = useState<filterData>(initialState);
  const [datePickerState, setDatePickerState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [open, setOpen] = useState(false);
  const [stats, setStats] = useState<null | Stats>(null);
  const [otherStats, setOtherStats] = useState<null | OtherStats>(null);
  const { access_token } = useSelector(getGlobalUser);
  const [allFiat, setAllFiat] = useState<null | string[]>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<null | string>(null);
  const { isMobile, isLargeDesktop } = useWidth();
  const { t } = useTranslation();
  const toggleOpenModal = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const fetch = async () => {
      const statsData = await getAllStatistics(filterQueries);
      const otherStatsData = await getOtherStatistics(filterQueries);
      const allFiatCurrencies = await getAllFiat(access_token);
      setAllFiat(allFiatCurrencies);
      setStats(statsData);
      setOtherStats(otherStatsData);
    };
    fetch();
  }, [filterQueries]);

  const handleChange = (item: ItemRange)=>{
    setDatePickerState([item.selection]);
  };

  const handleCurrencyChange = (_event: React.SyntheticEvent | null, newValue: string | null) => {
    const value = newValue === 'all' ? '' : (newValue as string);
    setSelectedCurrency(value);

    setFilterQueries(prev => {
      return {
        ...prev,
        sDate: formatDateBE(datePickerState[0].startDate),
        fDate: formatDateBE(datePickerState[0].endDate),
        currency: value
      };
    });

  };

  const handleAddDeadline = async () => {
    setFilterQueries(prev => {
      return {
        ...prev,
        sDate: formatDateBE(datePickerState[0].startDate),
        fDate: formatDateBE(datePickerState[0].endDate),
      };
    });

    setOpen(!open);
  };

  return (
    <Box sx={{ mb: 3, pl: (isMobile && '0') || 3, width: '100%' }}>
      <Typography
        level="h2"
        sx={{
          p: '20px 0 16px 0 ',
          fontWeight: 600,
          fontSize: isMobile ? '20px' : '32px',
          color: '#F1F1F1',
        }}
      >
        {t('dashboard_header')}
      </Typography>
      <Box sx={{ display: !isLargeDesktop ? 'block' : 'flex' }}>
        <Courses />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
        <Typography
          level="title-md"
          sx={{ fontWeight: 400, fontSize: isMobile ? '14px' : '16px', color: '#CED4DA' }}
        >
          {' '}
          {t('periods_search')}
        </Typography>
        <Box>
          <Button
            variant="outlined"
            color="neutral"
            onClick={toggleOpenModal}
            sx={{ p: isMobile ? '12px 8px' : '12px 19px' }}
          >
            {' '}
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" fill="none">
              <path
                fill="url(#a)"
                d="M18 9.5v7a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-7h18Zm-5-9a1 1 0 0 1 1 1v1h2a2 2 0 0 1 2 2v3H0v-3a2 2 0 0 1 2-2h2v-1a1 1 0 0 1 2 0v1h6v-1a1 1 0 0 1 1-1Z"
              />
              <defs>
                <linearGradient
                  id="a"
                  x1=".2"
                  x2="18"
                  y1="10.8"
                  y2="10.8"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#684FDD" />
                  <stop offset="1" stopColor="#2F92AC" />
                </linearGradient>
              </defs>
            </svg>
            <Typography sx={{ ml: 1, color: '#F1F1F1', fontSize: isMobile ? '10px' : '14px' }}>
              {(filterQueries.sDate &&
                  filterQueries.fDate &&
                  `${formatDateText(filterQueries.sDate)} - ${formatDateText(
                    filterQueries.fDate
                  )}`) ||
                t('search_period')}
            </Typography>
          </Button>
        </Box>

        <Box>
          <Select
            placeholder={t('dashboard_currency_rates')}
            variant="outlined"
            slotProps={{
              listbox: {
                sx: {
                  '--List-padding': '0px',
                  '--ListItem-radius': '0px',
                },
              },
            }}
            indicator={
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
                <path
                  fill="#ADB5BD"
                  d="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z"
                />
              </svg>
            }
            onChange={(_event: React.SyntheticEvent | null, newValue: string | null) => {
              handleCurrencyChange(_event, newValue);
            }}
            value={filterQueries.currency}
            sx={{
              color: '#CED4DA',
              width: '180px',
              p: 1,
              '--Input-radius': '8px',
              '--Select-placeholderOpacity': 1,
              mt: isMobile ? 1 : 0,
            }}
          >
            {allFiat &&
              allFiat.map((item) => (
                <Option key={item} value={item} sx={{
                  p: 0, color: '#F1F1F1',
                  '&[aria-selected="true"]': {
                    backgroundColor: '#684FDD',
                  }
                }}> <Typography sx={{
                  width: '100%', height: '100%', p: 1, color: '#F1F1F1',
                  '&:hover, &:focus': {
                    backgroundColor: '#684FDD',

                  },
                }}>{item}</Typography>
                </Option>
              ))}
          </Select>
        </Box>
      </Box>

      <Box sx={{ display: !isLargeDesktop ? 'block' : 'flex' }}>
        <Statistics otherStats={otherStats} filterQueries={filterQueries} />
        <Transactions stats={stats} filterQueries={filterQueries} />
      </Box>
      <Box sx={{ display: !isLargeDesktop ? 'block' : 'flex', paddingTop: '24px' }}>
        <Turnovers stats={stats} filterQueries={filterQueries} currency={selectedCurrency ?? 'UAH'} />
      </Box>

      {/* <ChartActivity />  */}
      <DatePickerModal
        handleChange={handleChange}
        open={open}
        setOpen={setOpen}
        datePickerState={datePickerState}
        handleAddDeadline={handleAddDeadline}
      />
    </Box>
  );
};

export default Dashboard;
